// Constants
const hamburger = document.querySelector('#hamburger');
const navigation = document.querySelector('#navigation');
const navLinks = document.querySelectorAll('.header--navigation ul li a');
const nav = document.querySelector('.header--navigation');
const accordionButtons = document.querySelectorAll('.accordion--button');
const showMoreButton = document.querySelector('#show-more');
const sections = document.querySelectorAll('.section');
const accordions = document.querySelectorAll('.accordion');

// Hamburger
function hamburgerToggle(): void {
    hamburger?.addEventListener('click', () => {
        hamburger?.classList.toggle('open');
        navigation?.classList.toggle('open');
    });
}

// Accordion
function accordionsClick(): void {
    accordionButtons.forEach((button) => {
        button.addEventListener('click', () => {
            button.classList.toggle('active');
            const accordionPanel = button.nextElementSibling;
            if (button.classList.contains('active')) {
                accordionPanel?.classList.toggle('show');
            } else {
                accordionPanel?.classList.remove('show');
            }
        });
    });
}

// Show more - remove hidden class from all accordions and remove show more button
function showMore(): void {
    showMoreButton?.addEventListener('click', () => {
        accordions.forEach((accordion) => {
            if (accordion.classList.contains('hidden')) {
                accordion.classList.remove('hidden');
            }
        });
        showMoreButton.remove();
    });
}

// Active state navigation
function activeNavigation(): void {
    function scrollActive(): void {
        const scrollY = window.pageYOffset;

        sections.forEach((section) => {
            const sectionHeight = section.offsetHeight;
            const sectionTop = section.offsetTop - 150;
            const sectionId = section.getAttribute('id');
            const anchor = document.querySelector(
                `.header--navigation ul li a[href*="${sectionId}"]`,
            );

            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                anchor?.classList.add('active');
            } else {
                anchor?.classList.remove('active');
            }
        });

        // Highlight second last nav item when scrolled to the bottom
        const secondLast = navLinks[navLinks.length - 2];
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            navLinks.forEach((navItem) => {
                navItem.classList.remove('active');
            });
            secondLast?.classList.add('active');
        }
    }

    window.addEventListener('scroll', scrollActive);
}

// Close navigation when clicked on ul li a on tablet and mobile
function closeNavigationOnMobile(): void {
    if (window.innerWidth < 992) {
        navLinks.forEach((navItem) => {
            navItem.addEventListener('click', () => {
                nav?.classList.remove('open');
                hamburger?.classList.remove('open');
            });
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    closeNavigationOnMobile();
    hamburgerToggle();
    accordionsClick();
    showMore();
    activeNavigation();
});
